import { AddressDto, CloudProductInfoDto, CountryDto, ProductInfoDto } from '@/castapi';
import { ISO_DATE_FORMAT, MDY_DATE_FORMAT, ISO_MS_DATE_FORMAT, MDY_FULL_FORMAT } from '@/shared/constants';
import { SUB_PRODUCT_LINE_IDS } from '@/shared/constants/subProductLineIds';
import { getExpiryOrSteppingDate } from '@/shared/functions';
import { format, parse, isBefore, startOfDay } from 'date-fns';
import { getCurrentInstance } from 'vue';
import { Framework } from 'vuetify';
import { Breakpoint } from 'vuetify/types/services/breakpoint';
import {
  addressLine as addressLineFn,
  getAddress as getAddressFn,
  isAddressValid as isAddressValidFn,
} from '@/castapi/helpers';

// NOTE: Date in string in "yyyy-MM-dd" format, without time and timezone!
export const formatDateToMdy = (date: string, srcFormat = ISO_DATE_FORMAT): string => {
  if (!date) {
    throw Error('Date must not be undefined');
    // return 'Date unknown';
  }
  return format(parse(date, srcFormat, new Date()), MDY_DATE_FORMAT);
};

// NOTE: Timestamp in string in "yyyy-MM-ddTHH:MM:ss.xxxZ" format, with time and timezone!
export const formatTimestampToMdy = (date: string, full = false): string => {
  if (date.length !== 24) {
    throw Error(`Date must be in "${ISO_MS_DATE_FORMAT}Z" format`);
  }
  return format(new Date(date), full ? MDY_DATE_FORMAT : MDY_FULL_FORMAT);
};

export const formatTimestampToMdyInUTC = (date: string): string => {
  if (date.length !== 24) {
    throw Error(`Date must be in "${ISO_MS_DATE_FORMAT}Z" format`);
  }
  const datee = new Date(date);
  const isoDate = datee.toISOString();
  const dateInUtc = `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;
  return `${format(new Date(dateInUtc), MDY_FULL_FORMAT)} (UTC)`;
};

export const isDateValid = (date: string): boolean => {
  if (!date) {
    return false;
  }
  const exp = new Date(formatDateToMdy(date));
  const today = new Date(format(new Date(), MDY_DATE_FORMAT));
  return !isBefore(new Date(exp), new Date(today));
};

export const isProductObsolete = (product: ProductInfoDto): boolean => {
  return isDateValid(getExpiryOrSteppingDate(product));
};

export const getProductObsoleteDateAndStatus = (product: ProductInfoDto, short = false): string => {
  const obsoleteDate = getExpiryOrSteppingDate(product);
  if (!obsoleteDate) {
    return 'Not burned';
  }
  const label = isDateValid(obsoleteDate) ? `Valid${short ? '' : ' until'}` : 'Expired on';
  return `${label} ${formatDateToMdy(obsoleteDate)}`;
};

export const isLicenseValid = (product: CloudProductInfoDto): boolean => {
  return isBefore(startOfDay(new Date()), new Date(product.expiryDate));
};

export const isProductValid = (product: ProductInfoDto | CloudProductInfoDto): boolean => {
  if ((product as ProductInfoDto).subProductLineRef === SUB_PRODUCT_LINE_IDS.LEARN) {
    return true;
  }
  return (product as CloudProductInfoDto).cloudProductId
    ? isLicenseValid(product as CloudProductInfoDto)
    : isProductObsolete(product as ProductInfoDto);
};

export const getLicenseExpirationDate = (product: CloudProductInfoDto): string => {
  return formatDateToMdy(product.expiryDate);
};

export const getLicenseExpirationDateAndStatus = (product: CloudProductInfoDto, short = false): string => {
  const isExpired = !isLicenseValid(product);
  const expirationDate = getLicenseExpirationDate(product);
  return `${isExpired ? 'Expired on' : `Valid${short ? '' : ' until'}`} ${expirationDate}`;
};

export const getExpirationDateAndStatus = (product: ProductInfoDto | CloudProductInfoDto, short = false): string => {
  if ((product as ProductInfoDto).subProductLineRef === SUB_PRODUCT_LINE_IDS.LEARN) {
    return 'No expiration date';
  }
  return (product as CloudProductInfoDto).cloudProductId
    ? getLicenseExpirationDateAndStatus(product as CloudProductInfoDto, short)
    : getProductObsoleteDateAndStatus(product as ProductInfoDto, short);
};

export const getProductCode = (product: ProductInfoDto | CloudProductInfoDto): string => {
  return (product as ProductInfoDto).serialCode || (product as CloudProductInfoDto).licenseCode;
};

export const getRange = (start: number, end: number, step?: number): number[] => {
  const arr: number[] = [];
  for (let i = start; i <= end; step ? (i = i + step) : i++) {
    arr.push(i);
  }
  return arr;
};

export const addressLine = (address: undefined | Partial<AddressDto>, countries: CountryDto[]): string => {
  return address ? addressLineFn(address, countries) : '';
};

export const isAddressValid = (address?: AddressDto): boolean => {
  return isAddressValidFn(address);
};

export const getAddress = (address?: AddressDto): undefined | Partial<AddressDto> => {
  return getAddressFn(address);
};

export const getVuetifyInstance = (): Framework => {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error('Can not get current instance');
  }
  if (!instance.proxy) {
    throw new Error('Can not get proxy');
  }
  if (!instance.proxy.$vuetify) {
    throw new Error('Can not get $vuetify');
  }
  return instance.proxy.$vuetify;
};
export const getBreakpoint = (): Breakpoint => getVuetifyInstance().breakpoint;
