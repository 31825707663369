import { AppLogger } from '@/logger';
import Vue from 'vue';
import { pick } from '@/shared/functions';
import { getEmailTemplatesApi, getErrorMessage } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';
import { EmailTemplateDto, UpdateEmailTemplateDto } from '@/castapi';

const logger = new AppLogger('adminEmailTemplates state');

interface IAdminEmailTemplatesState {
  emailTemplates: EmailTemplateDto[];
  emailTemplatesLoading: boolean;
  emailTemplatesLoadError?: string;
  emailTemplate?: EmailTemplateDto;
  emailTemplateLoading: boolean;
  emailTemplateLoadError?: string;
  emailTemplateSaving: boolean;
  emailTemplateSaveError?: string;
}

const initialState = (): IAdminEmailTemplatesState => ({
  emailTemplates: [],
  emailTemplatesLoading: false,
  emailTemplatesLoadError: undefined,
  emailTemplate: undefined,
  emailTemplateLoading: false,
  emailTemplateLoadError: undefined,
  emailTemplateSaving: false,
  emailTemplateSaveError: undefined,
});

type ActionParams = IActionParams<IAdminEmailTemplatesState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    EMAIL_TEMPLATES_LOADING(state: IAdminEmailTemplatesState): void {
      state.emailTemplatesLoadError = undefined;
      state.emailTemplatesLoading = true;
    },
    EMAIL_TEMPLATES_LOADED(state: IAdminEmailTemplatesState, payload: EmailTemplateDto[]): void {
      state.emailTemplates = payload;
      state.emailTemplatesLoading = false;
    },
    EMAIL_TEMPLATES_LOAD_ERROR(state: IAdminEmailTemplatesState, error: Error): void {
      state.emailTemplatesLoadError = getErrorMessage(error);
      state.emailTemplatesLoading = false;
    },
    EMAIL_TEMPLATE_LOADING(state: IAdminEmailTemplatesState): void {
      state.emailTemplateLoadError = undefined;
      state.emailTemplateLoading = true;
    },
    EMAIL_TEMPLATE_LOADED(state: IAdminEmailTemplatesState, payload: EmailTemplateDto): void {
      state.emailTemplate = payload;
      state.emailTemplateLoading = false;
    },
    EMAIL_TEMPLATE_LOAD_ERROR(state: IAdminEmailTemplatesState, error: Error): void {
      state.emailTemplatesLoadError = getErrorMessage(error);
      state.emailTemplateLoading = false;
    },
    EMAIL_TEMPLATE_UPDATE_IN_PROGRESS(state: IAdminEmailTemplatesState): void {
      state.emailTemplateSaving = true;
      state.emailTemplateSaveError = undefined;
    },
    EMAIL_TEMPLATE_UPDATE_SUCCESS(state: IAdminEmailTemplatesState, payload: EmailTemplateDto): void {
      const ind = state.emailTemplates.findIndex(item => item.id === payload.id);
      Vue.set(state.emailTemplates, ind, payload);
      state.emailTemplateSaving = false;
    },
    EMAIL_TEMPLATE_UPDATE_ERROR(state: IAdminEmailTemplatesState, error: Error): void {
      state.emailTemplateSaving = false;
      state.emailTemplateSaveError = getErrorMessage(error);
    },
    RESET_STATE(state: IAdminEmailTemplatesState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    RESET_EDITOR_STATE(state: IAdminEmailTemplatesState): void {
      state.emailTemplate = undefined;
      state.emailTemplateLoading = false;
      state.emailTemplateLoadError = undefined;
      state.emailTemplateSaving = false;
      state.emailTemplateSaveError = undefined;
    },
  },
  actions: {
    async loadEmailTemplates({ commit, rootGetters }: ActionParams): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('EMAIL_TEMPLATES_LOADING');
        const response = await getEmailTemplatesApi(accessToken).emailTemplatesControllerFindAll();
        commit('EMAIL_TEMPLATES_LOADED', response.data);
      } catch (error) {
        commit('EMAIL_TEMPLATES_LOAD_ERROR', error);
        logger.captureStoreError('loadEmailTemplates', error);
      }
    },
    async loadEmailTemplate({ commit, rootGetters }: ActionParams, id: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('EMAIL_TEMPLATE_LOADING');
        const response = await getEmailTemplatesApi(accessToken).emailTemplatesControllerFindOne(id);
        commit('EMAIL_TEMPLATE_LOADED', response.data);
      } catch (error) {
        commit('EMAIL_TEMPLATE_LOAD_ERROR', error);
        logger.captureStoreError('loadEmailTemplates', error, { id });
      }
    },
    async updateEmailTemplate({ commit, rootGetters }: ActionParams, emailTemplate: EmailTemplateDto): Promise<void> {
      try {
        commit('EMAIL_TEMPLATE_UPDATE_IN_PROGRESS');
        const emailTemplateUpdateDto = pick(emailTemplate, ['id', 'subject', 'template']) as UpdateEmailTemplateDto;
        await getEmailTemplatesApi(rootGetters['login/accessToken']).emailTemplatesControllerUpdate(
          emailTemplate.id,
          emailTemplateUpdateDto,
        );
        commit('EMAIL_TEMPLATE_UPDATE_SUCCESS', emailTemplate);
      } catch (error) {
        commit('EMAIL_TEMPLATE_UPDATE_ERROR', error);
        logger.captureStoreError('updateEmailTemplate', error, { emailTemplate });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }: ActionParams): void {
      commit('RESET_EDITOR_STATE');
    },
  },
  getters: {
    emailTemplates: (state: IAdminEmailTemplatesState): EmailTemplateDto[] => state.emailTemplates,
    emailTemplatesLoading: (state: IAdminEmailTemplatesState): boolean => state.emailTemplatesLoading,
    emailTemplatesLoadError: (state: IAdminEmailTemplatesState): undefined | string => state.emailTemplatesLoadError,
    emailTemplate: (state: IAdminEmailTemplatesState): undefined | EmailTemplateDto => state.emailTemplate,
    emailTemplateLoading: (state: IAdminEmailTemplatesState): boolean => state.emailTemplateLoading,
    emailTemplateLoadError: (state: IAdminEmailTemplatesState): undefined | string => state.emailTemplateLoadError,
    emailTemplateSaving: (state: IAdminEmailTemplatesState): boolean => state.emailTemplateSaving,
    emailTemplateSaveError: (state: IAdminEmailTemplatesState): undefined | string => state.emailTemplateSaveError,
  },
};
