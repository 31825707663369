import { DemoDto, DemoLeadCreateDto, DemoLeadDto, ProfessionDto } from '@/castapi';
import { AppLogger } from '@/logger';
import { getDemosApi, getErrorMessage } from '@/castapi/helpers';
import { downloadFileUsingUrl } from '@/shared/functions';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('demo state');

interface IDemosState {
  availableDemos: DemoDto[];
  availableDemosLoading: boolean;
  availableDemosLoadError?: string;
  demoLinkLoading: boolean;
  demoLinkLoadError?: string;
  demoLeads?: DemoLeadDto;
  demoLeadsLoading: boolean;
  demoLeadsLoadError?: string;
  professions: ProfessionDto[];
  professionsLoading: boolean;
  professionsLoadError?: string;
}

const initialState = (): IDemosState => ({
  availableDemos: [],
  availableDemosLoading: false,
  availableDemosLoadError: undefined,
  demoLinkLoading: false,
  demoLinkLoadError: undefined,
  demoLeads: undefined,
  demoLeadsLoading: false,
  demoLeadsLoadError: undefined,
  professions: [],
  professionsLoading: false,
  professionsLoadError: undefined,
});

type ActionParams = IActionParams<IDemosState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state: IDemosState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    DEMOS_LOADING(state: IDemosState): void {
      state.availableDemos = [];
      state.availableDemosLoading = true;
      state.availableDemosLoadError = undefined;
    },
    DEMOS_LOADED(state: IDemosState, demos: DemoDto[]): void {
      state.availableDemos = demos;
      state.availableDemosLoading = false;
    },
    DEMOS_LOAD_ERROR(state: IDemosState, payload: Error): void {
      state.availableDemosLoading = false;
      state.availableDemosLoadError = getErrorMessage(payload);
    },
    DEMO_LOADING(state: IDemosState): void {
      state.demoLinkLoading = true;
      state.demoLinkLoadError = undefined;
    },
    DEMO_LOADED(state: IDemosState): void {
      state.demoLinkLoading = false;
    },
    DEMO_LOAD_ERROR(state: IDemosState, payload: Error): void {
      state.demoLinkLoading = false;
      state.demoLinkLoadError = getErrorMessage(payload);
    },
    DEMO_LEADS_LOADING(state: IDemosState): void {
      state.demoLeads = undefined;
      state.demoLeadsLoading = true;
      state.demoLeadsLoadError = undefined;
    },
    DEMO_LEADS_LOADED(state: IDemosState, demoLeads: DemoLeadDto): void {
      state.demoLeads = demoLeads;
      state.demoLeadsLoading = false;
    },
    DEMO_LEADS_LOAD_ERROR(state: IDemosState, payload: Error): void {
      state.demoLeadsLoading = false;
      state.demoLeadsLoadError = getErrorMessage(payload);
    },
    PROFESSIONS_LOADING(state: IDemosState): void {
      state.professions = [];
      state.professionsLoading = true;
      state.professionsLoadError = undefined;
    },
    PROFESSIONS_LOADED(state: IDemosState, professions: ProfessionDto[]): void {
      state.professions = professions;
      state.professionsLoading = false;
    },
    PROFESSIONS_LOAD_ERROR(state: IDemosState, payload: Error): void {
      state.professionsLoading = false;
      state.professionsLoadError = getErrorMessage(payload);
    },
  },
  actions: {
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    async loadDemos({ commit, rootGetters }: ActionParams, token: string): Promise<void> {
      try {
        commit('DEMOS_LOADING');
        const response = await getDemosApi(token || rootGetters['login/accessToken']).demoControllerFindAll();
        commit('DEMOS_LOADED', response.data);
      } catch (error) {
        commit('DEMOS_LOAD_ERROR', error);
        logger.captureStoreError('loadDemos', error);
      }
    },
    async downloadDemo({ commit, rootGetters }: ActionParams, demoLead: DemoLeadCreateDto): Promise<void> {
      const organizationRef = rootGetters['organizations/organization'].organizationId;
      try {
        commit('DEMO_LOADING');
        const response = await getDemosApi(rootGetters['login/accessToken']).demoControllerGetDemoDownloadLink({
          ...demoLead,
          organizationRef,
        });
        const url = response.data;
        downloadFileUsingUrl(url);
        commit('DEMO_LOADED');
      } catch (error) {
        commit('DEMO_LOAD_ERROR', error);
        logger.captureStoreError('downloadDemo', error, { demoLead });
      }
    },
    async loadDemoLeads({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('DEMO_LEADS_LOADING');
        const response = await getDemosApi(rootGetters['login/accessToken']).demoControllerGetDemoLeadsForCurrentUser();
        commit('DEMO_LEADS_LOADED', response.data);
      } catch (error) {
        commit('DEMO_LEADS_LOAD_ERROR', error);
        logger.captureStoreError('loadDemoLeads', error);
      }
    },
    async loadProfessions({ commit, rootGetters }: ActionParams, productLine: number): Promise<void> {
      try {
        commit('PROFESSIONS_LOADING');
        const response = await getDemosApi(rootGetters['login/accessToken']).demoControllerGetProfessions(productLine);
        commit('PROFESSIONS_LOADED', response.data);
      } catch (error) {
        commit('PROFESSIONS_LOAD_ERROR', error);
        logger.captureStoreError('loadProfessions', error, { productLine });
      }
    },
  },
  getters: {
    availableDemos: (state: IDemosState): DemoDto[] => state.availableDemos,
    demoLeads: (state: IDemosState): undefined | DemoLeadDto => state.demoLeads,
    demoLeadsLoading: (state: IDemosState): boolean => state.demoLeadsLoading,
    professions: (state: IDemosState): ProfessionDto[] => state.professions,
    professionsLoading: (state: IDemosState): boolean => state.professionsLoading,
  },
};
