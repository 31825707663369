<template>
  <v-app id="app">
    <TopToolbar :key="route.fullPath" />
    <Snackbar />
    <v-main>
      <v-container class="main-container">
        <router-view :key="route.path" />
      </v-container>
    </v-main>
    <product-fruits
      v-if="isProductFruitsOn && user"
      project-code="ocblOqrTQ6B2jMXZ"
      language="en"
      :username="user.firstName + ' ' + user.lastName"
      :email="user.email"
      :firstname="user.firstName"
      :lastname="user.lastName"
      :sign-up-at="user.lastLoginDate"
      :role="role"
    />
  </v-app>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import store from '@/store';
import TopToolbar from '@/components/shared/common/MoaTopToolbar.vue';
import Snackbar from '@/components/shared/common/MoaSnackbar.vue';
import { UserDto } from '@/castapi';
import ProductFruits from '@/components/shared/integrations/productFruits';

const urlsWithProductFruits = ['app.cast-soft.com' /*, 'develop.id.cast-soft.com'*/ /*, 'localhost'*/];
const route = useRoute();
const user = computed<UserDto>(() => store.getters['login/user']);
const role = computed<undefined | string>(() => store.getters['login/roleName']);
const isProductFruitsOn = computed<boolean>(() =>
  urlsWithProductFruits.some(url => window.location.href.includes(url)),
);
onMounted(async () => {
  if (store.getters['login/loggedIn']) {
    await store.dispatch('login/RELOAD_CURRENT_USER_DATA');
  }
});
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
.main-container {
  max-width: 1440px !important;
  height: 100%;
  min-height: calc(100vh - 64px);
}
</style>
