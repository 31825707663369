<template>
  <header>
    <div>
      <!-- Navigation drawer -->
      <v-navigation-drawer v-model="sidebar" fixed temporary @input="navStateChanged">
        <v-list nav dense>
          <template v-for="(item, index) in userNavigationLinks">
            <v-list-group v-if="item.subMenus" :key="index" :data-cy="item.dataCy">
              <template v-slot:activator>
                <v-list-item-title>{{ item.tab }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(submenuItem, i) in item.subMenus"
                :key="i"
                :to="submenuItem.route"
                :data-cy="submenuItem.dataCy"
              >
                <v-list-item-title>{{ submenuItem.tab }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item :key="index" v-else :to="item.route" :data-cy="item.dataCy">
              <v-list-item-content>
                <v-list-item-title>{{ item.tab }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <!-- App bar -->
      <v-app-bar :elevation="1" app>
        <v-app-bar-nav-icon
          class="d-lg-none"
          @click="sidebar = true"
          v-if="userNavigationLinks.length"
          aria-label="Navigation menu button"
        />
        <router-link :to="getHomePath()">
          <img alt="Home" src="@/assets/images/cast-logo-black-green.svg" width="120" height="22" />
        </router-link>
        <!-- Toolbar -->
        <v-toolbar-items class="d-none d-lg-flex">
          <template v-for="(item, index) in userNavigationLinks">
            <v-menu offset-y v-if="item.subMenus" :key="index">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" :aria-label="item.tab" :data-cy="item.dataCy"> {{ item.tab }} </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(submenu, i) in item.subMenus"
                  :key="i"
                  :to="submenu.route"
                  :data-cy="submenu.dataCy"
                >
                  <v-list-item-title class="text-uppercase">{{ submenu.tab }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-else text :key="index" :to="item.route" :aria-label="item.tab" :data-cy="item.dataCy">
              {{ item.tab }}
            </v-btn>
          </template>
        </v-toolbar-items>
        <v-spacer />
        <!-- Shopping cart -->
        <v-btn
          v-if="userRoleName === 'owner' || userRoleName === 'demo'"
          icon
          @click="goToCart"
          aria-label="Go to cart button"
          data-cy="go-to-cart-button"
        >
          <v-badge
            :content="cartProductsCount"
            :value="cartProductsCount"
            color="primary"
            overlap
            data-cy="items-in-cart-count"
          >
            <v-icon>shopping_cart</v-icon>
          </v-badge>
        </v-btn>
        <!-- Menu -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-large
              v-on="on"
              class="mx-2"
              :class="{ 'mr-0': !isNotXs }"
              aria-label="Toggle profile menu top button"
              data-cy="toggle-profile-menu-top"
            >
              <moa-avatar
                :img="user?.avatar"
                :full-name="getUserFullName(user)"
                :size="48"
                data-cy="header-user-avatar"
              />
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="profileLink in profileLinks"
              :key="profileLink.tab"
              :to="profileLink.route"
              :data-cy="profileLink.dataCy"
              link
              @click="handleProfileLink(profileLink.tab)"
            >
              <v-list-item-title>{{ profileLink.tab }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-if="user && isNotXs" class="text-caption d-grid">
          <div
            :title="getUserFullName(user)"
            class="text-uppercase font-weight-bold text-truncate"
            data-cy="header-user-name"
          >
            {{ getUserFullName(user) }}
          </div>
          <div :title="user.email" class="text-truncate" data-cy="user-email-top">{{ user.email }}</div>
        </div>
      </v-app-bar>
    </div>
    <v-dialog v-model="dialogLegal" max-width="400">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Legal</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialogLegal = false"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="mt-2">
            <a
              href="https://cast-soft.com/cast/files/End_User_Licence_Agreement.pdf"
              target="_blank"
              rel="noopener noreferrer"
              >EULA</a
            >
          </div>
          <div class="mt-1"><a href="https://cast-soft.com/terms-of-use">Terms of use</a></div>
          <div class="mt-1"><a href="https://cast-soft.com/privacy-policy/">Privacy Policy</a></div>
          <div class="mt-1 mb-2">
            <a href="https://cast-soft.com/cast/files/Terms-Conditions.pdf" target="_blank" rel="noopener noreferrer"
              >General Sales Terms & Conditions</a
            >
          </div>
          Copyright ©{{ thisYear }} CAST Software. All rights reserved.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="dialogLegal = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </header>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import store from '@/store';
import { getBreakpoint } from '@/mixins/ProjectMixinComposition';
import MoaAvatar from '@/components/shared/common/MoaAvatar.vue';
import { getUserFullName, isCloudOn, isTestEnv } from '@/shared/functions';
import { getHomePath, routes } from '@/router';
import { YEAR_FORMAT } from '@/shared/constants';
import { DemoDto, UserDto } from '@/castapi';
import { Route } from 'vue-router';

type NavigationLink = {
  tab: string;
  route?: string;
  dataCy?: string;
  hidden?: boolean;
  roles?: string[];
  subMenus?: NavigationLink[];
};
type ProfileLink = {
  tab: string;
  route?: string | Route;
  dataCy: string;
};

const router = useRouter();
const breakpoint = computed(() => getBreakpoint());
const sidebar = ref(false);
const dialogLegal = ref(false);
const thisYear = ref(YEAR_FORMAT);

const handleProfileLink = (tab: string): void => {
  if (tab === 'Legal') {
    dialogLegal.value = true;
  }
};
const navStateChanged = ($event: boolean): void => {
  return $event
    ? document.body.classList.add('disable-scrolling')
    : document.body.classList.remove('disable-scrolling');
};
const availableDemos = computed<DemoDto[]>(() => store.getters['demo/availableDemos']);
const demoSubmenus = computed<NavigationLink[]>(() =>
  availableDemos.value.map((demo: DemoDto) => ({
    tab: demo.name?.toUpperCase(),
    route: `/demo/${demo.demoLinkName}`,
    dataCy: `link-demo-${demo.name?.toLowerCase()}`,
  })),
);
const otherSubmenus = computed<NavigationLink[]>(() => {
  const submenus = [
    {
      tab: 'Feedbacks',
      route: '/feedbacks',
    },
    {
      tab: 'Pending Users',
      route: '/pending-users',
    },
    {
      tab: 'Email Templates',
      route: '/email-templates',
    },
  ];
  if (isTestEnv()) {
    submenus.push({
      tab: 'Dongle-Web Simulator',
      route: '/dongle-web-simulator',
    });
  }
  return submenus;
});
const navigationLinks = computed<NavigationLink[]>(() =>
  [
    {
      tab: 'Products',
      route: '/products',
      dataCy: 'link-products-top',
    },
    {
      tab: 'Downloads',
      route: '/downloads',
      dataCy: 'link-downloads-top',
    },
    {
      tab: 'Demos',
      dataCy: 'link-demos',
      subMenus: demoSubmenus.value,
      roles: ['demo', 'owner', 'member'],
    },
    {
      tab: 'Store',
      route: '/shop',
      dataCy: 'link-store',
    },
    {
      tab: 'Organization',
      route: '/organization',
      dataCy: 'link-organization',
    },
    {
      tab: 'People',
      route: '/people',
      dataCy: 'link-people-top',
    },
    {
      tab: 'Invoices',
      route: '/invoices',
      dataCy: 'link-invoices-top',
    },
    {
      tab: 'Dongles',
      route: '/dongles',
      dataCy: 'nav-link-dongles',
    },
    {
      tab: 'Cloud',
      route: '/cloud-products',
      dataCy: 'nav-link-cloud',
      hidden: !isCloudOn(),
    },
    {
      tab: 'Users',
      route: '/users',
      dataCy: 'nav-link-users',
    },
    {
      tab: 'Organizations',
      route: '/organizations',
      dataCy: 'nav-link-organizations',
    },
    {
      tab: 'Uploads',
      dataCy: 'nav-link-uploads',
      roles: ['admin'],
      subMenus: [
        {
          tab: 'Releases',
          route: '/uploads',
          dataCy: 'nav-link-releases',
        },
        {
          tab: 'Demos',
          route: '/admin-demos',
          dataCy: 'nav-link-admin-demos',
        },
      ],
    },
    {
      tab: 'Discounts',
      route: '/discounts',
      dataCy: 'nav-link-discounts',
    },
    {
      tab: 'Staff',
      route: '/staff',
      dataCy: 'nav-link-staff',
    },
    {
      tab: 'Orders',
      route: '/orders',
      dataCy: 'nav-link-orders',
    },
    {
      tab: 'Inventory',
      route: '/inventory',
      dataCy: 'nav-link-inventory',
    },
    {
      tab: 'Other',
      roles: ['admin'],
      subMenus: otherSubmenus.value,
    },
    {
      tab: 'Support',
      route: '/support-request',
      dataCy: 'nav-link-inventory',
    },
  ].filter(link => !link.hidden),
);
const user = computed<undefined | UserDto>(() => store.getters['login/user']);
const isAdmin = computed<boolean>(() => store.getters['login/isAdmin']);
const userRoleName = computed<undefined | string>(() => store.getters['login/roleName']);
const isNotXs = computed<boolean>(() => breakpoint.value.width > 480);
const userNavigationLinks = computed<NavigationLink[]>(() => {
  return navigationLinks.value.filter(link => {
    const role = isAdmin.value ? 'admin' : userRoleName?.value || '';
    if (!link.route && link.subMenus && link.roles) {
      return link.roles.includes(role);
    }
    if (link.route) {
      const route = routes.find(r => r.path === link.route);
      if (!route) {
        throw new Error('Can not find route for navigation link');
      }
      return route.meta.roles.includes(role);
    }
    throw new Error('Can not find route for navigation link');
  });
});
const profileLinks = computed<ProfileLink[]>(() =>
  store.getters['login/loggedIn']
    ? [
        { tab: 'Profile', route: '/profile', dataCy: 'profile-link-top' },
        { tab: 'Legal', dataCy: 'legal-link-top' },
        // { tab: 'Feedback', route: '/feedback', dataCy: 'feedback' },
        { tab: 'Sign Out', route: '/auth/signout', dataCy: 'logout-link-top' },
      ]
    : [
        { tab: 'Legal', dataCy: 'legal-link-top' },
        { tab: 'Sign In', route: '/signin', dataCy: 'login-link-top' },
      ],
);
const cartProductsCount = computed<number>(() => store.getters['shop/cartProductsCount']);
const goToCart = async (): Promise<void> => {
  await router.push({ name: 'shop-cart' });
};
onMounted(() => {
  document.body.classList.remove('disable-scrolling');
});
</script>
