import { getCloudApi } from '@/castapi/helpers';
import { getErrorMessage, ISearchParams } from '@/castapi/helpers';
import { AppLogger } from '@/logger';
import { CloudProductInfoDto, CloudProductSearchResponseDto, ComputerDto, HttpExceptionDto } from '@/castapi';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminCloudProducts state');

interface IAdminCloudProductsState {
  currentCloudProduct?: CloudProductInfoDto;
  cloudProducts: CloudProductSearchResponseDto[];
  cloudProductsLoading: boolean;
  cloudProductsLoadError?: string;
  cloudProductChanging: boolean;
  assignCloudProductError?: string;
  notesChangeError?: string;
  organizationAndUserCloudProductsLoading: boolean;
  organizationAndUserCloudProductsLoadError?: string;
  userCloudProductsLoading: boolean;
  userCloudProductsLoadError?: string;
  organizationAndUserCloudProducts: CloudProductInfoDto[];
  computers: ComputerDto[];
  computersLoading: boolean;
  computersLoadError?: string;
}

const initialState = (): IAdminCloudProductsState => ({
  currentCloudProduct: undefined,
  cloudProducts: [],
  cloudProductsLoading: false,
  cloudProductsLoadError: undefined,
  cloudProductChanging: false,
  assignCloudProductError: undefined,
  notesChangeError: undefined,
  organizationAndUserCloudProductsLoading: false,
  organizationAndUserCloudProductsLoadError: undefined,
  userCloudProductsLoading: false,
  userCloudProductsLoadError: undefined,
  organizationAndUserCloudProducts: [],
  computers: [],
  computersLoading: false,
  computersLoadError: undefined,
});

type ActionParams = IActionParams<IAdminCloudProductsState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SELECT_CLOUD_PRODUCT(state: IAdminCloudProductsState, cloudProduct: CloudProductInfoDto): void {
      state.currentCloudProduct = cloudProduct;
    },

    CLOUD_PRODUCTS_LOADING(state: IAdminCloudProductsState): void {
      state.cloudProductsLoadError = undefined;
      state.cloudProductsLoading = true;
    },

    CLOUD_PRODUCTS_LOADED(state: IAdminCloudProductsState, payload: CloudProductSearchResponseDto[]): void {
      state.cloudProducts = payload;
      state.cloudProductsLoading = false;
    },

    CLOUD_PRODUCTS_LOAD_ERROR(state: IAdminCloudProductsState, error: Error | HttpExceptionDto): void {
      state.cloudProductsLoadError = getErrorMessage(error);
      state.cloudProductsLoading = false;
    },

    CLOUD_PRODUCT_CHANGING(state: IAdminCloudProductsState): void {
      state.assignCloudProductError = undefined;
      state.cloudProductChanging = true;
    },

    CLOUD_PRODUCT_CHANGED(state: IAdminCloudProductsState): void {
      state.cloudProductChanging = false;
      state.assignCloudProductError = undefined;
      state.notesChangeError = undefined;
    },

    ASSIGN_CLOUD_PRODUCT_ERROR(state: IAdminCloudProductsState, error: Error | HttpExceptionDto): void {
      state.cloudProductChanging = false;
      state.assignCloudProductError = getErrorMessage(error);
    },

    NOTES_CLOUD_PRODUCT_ERROR(state: IAdminCloudProductsState, error: Error | HttpExceptionDto): void {
      state.cloudProductChanging = false;
      state.notesChangeError = getErrorMessage(error);
    },

    ORGANIZATION_CLOUD_PRODUCTS_LOADING(state: IAdminCloudProductsState): void {
      state.organizationAndUserCloudProductsLoadError = undefined;
      state.organizationAndUserCloudProductsLoading = true;
    },

    ORGANIZATION_CLOUD_PRODUCTS_LOADED(state: IAdminCloudProductsState, payload: CloudProductInfoDto[]): void {
      state.organizationAndUserCloudProducts = payload;
      state.organizationAndUserCloudProductsLoading = false;
    },

    ORGANIZATION_CLOUD_PRODUCTS_LOAD_ERROR(state: IAdminCloudProductsState, error: Error | HttpExceptionDto): void {
      state.organizationAndUserCloudProductsLoadError = getErrorMessage(error);
      state.organizationAndUserCloudProductsLoading = false;
    },

    USER_CLOUD_PRODUCTS_LOADING(state: IAdminCloudProductsState): void {
      state.userCloudProductsLoadError = undefined;
      state.userCloudProductsLoading = true;
    },

    USER_CLOUD_PRODUCTS_LOADED(state: IAdminCloudProductsState, payload: CloudProductInfoDto[]): void {
      state.organizationAndUserCloudProducts = payload;
      state.userCloudProductsLoading = false;
    },

    USER_CLOUD_PRODUCTS_LOAD_ERROR(state: IAdminCloudProductsState, error: Error | HttpExceptionDto): void {
      state.userCloudProductsLoadError = getErrorMessage(error);
      state.userCloudProductsLoading = false;
    },

    COMPUTERS_LOADING(state: IAdminCloudProductsState): void {
      state.computers = [];
      state.computersLoadError = undefined;
      state.computersLoading = true;
    },

    COMPUTERS_LOADED(state: IAdminCloudProductsState, computers: ComputerDto[]): void {
      state.computers = computers;
      state.computersLoading = false;
    },

    COMPUTERS_LOAD_ERROR(state: IAdminCloudProductsState, error: Error | HttpExceptionDto): void {
      state.computersLoading = false;
      state.computersLoadError = getErrorMessage(error);
    },

    CLEAR_ORGANIZATION_CLOUD_PRODUCTS(state: IAdminCloudProductsState): void {
      state.cloudProducts = [];
      state.computers = [];
    },

    RESET_STATE(state: IAdminCloudProductsState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadCloudProducts({ commit, rootGetters }: ActionParams, searchParam: ISearchParams): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParam || {};
      commit('CLOUD_PRODUCTS_LOADING', {
        limit: searchParam.limit,
        offset: searchParam.offset,
        searchText: searchParam.searchText,
      });
      try {
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerGetAllCloudProducts(
          searchParam.limit,
          searchParam.offset,
          searchParam.searchText,
          searchParam.sortBy,
          searchParam.sortDesc,
        );
        commit('CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadCloudProducts', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async loadVisibleCloudProducts(
      { dispatch, rootGetters }: ActionParams,
      fromPage: 'organizations' | 'user',
    ): Promise<void> {
      if (fromPage === 'organizations') {
        const organizationId = rootGetters['adminOrganizations/currentOrganizationId'];
        await dispatch('loadOrganizationCloudProducts', organizationId);
      } else if (fromPage === 'user') {
        const userId = rootGetters['adminUsers/editingUserId'];
        await dispatch('loadUserCloudProducts', userId);
      }
    },

    async loadOrganizationCloudProducts({ commit, rootGetters }: ActionParams, organizationId: number): Promise<void> {
      try {
        commit('ORGANIZATION_CLOUD_PRODUCTS_LOADING');
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindAllByOrganization(
          organizationId,
        );
        commit('ORGANIZATION_CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATION_CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizationCloudProducts', error, { organizationId });
      }
    },

    async loadUserCloudProducts({ commit, rootGetters }: ActionParams, userId: number): Promise<void> {
      try {
        commit('USER_CLOUD_PRODUCTS_LOADING');
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindAllByUser(userId);
        commit('USER_CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('USER_CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadUserCloudProducts', error, { userId });
      }
    },

    async assignCloudProduct(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        cloudProductId,
        userId,
        fromPage,
      }: { cloudProductId: number; userId: number; fromPage: 'organizations' | 'user' },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('CLOUD_PRODUCT_CHANGING');
        await getCloudApi(accessToken).cloudControllerAssignCloudProduct({ cloudProductId, userId });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadVisibleCloudProducts', fromPage);
      } catch (error) {
        commit('ASSIGN_CLOUD_PRODUCT_ERROR', error);
        logger.captureStoreError('assignCloudProduct', error, { cloudProductId, userId, fromPage });
      }
    },

    async unAssignCloudProduct(
      { commit, dispatch, rootGetters }: ActionParams,
      { cloudProductId, fromPage }: { cloudProductId: number; fromPage: 'organizations' | 'user' },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('CLOUD_PRODUCT_CHANGING');
        await getCloudApi(accessToken).cloudControllerUnAssignCloudProduct({ cloudProductId });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadVisibleCloudProducts', fromPage);
      } catch (error) {
        commit('ASSIGN_CLOUD_PRODUCT_ERROR', error);
        logger.captureStoreError('unAssignCloudProduct', error, { cloudProductId, fromPage });
      }
    },

    async updateCloudProductNotes(
      { commit, dispatch, rootGetters }: ActionParams,
      { cloudProductId, notes, fromPage }: { cloudProductId: number; notes: string; fromPage: string },
    ): Promise<void> {
      commit('CLOUD_PRODUCT_CHANGING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerUpdateCloudProductNotes({
          cloudProductId,
          notes,
        });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadVisibleCloudProducts', fromPage);
      } catch (error) {
        commit('NOTES_CLOUD_PRODUCT_ERROR', error);
        logger.captureStoreError('updateDongleNotes', error, { cloudProductId, notes, fromPage });
      }
    },

    async selectCloudProduct({ commit, dispatch }: ActionParams, cloudProduct: CloudProductInfoDto): Promise<void> {
      commit('SELECT_CLOUD_PRODUCT', cloudProduct);
      await dispatch('loadComputers', cloudProduct.cloudProductId);
    },

    async loadComputers({ commit, rootGetters }: ActionParams, cloudProductId: number): Promise<void> {
      commit('COMPUTERS_LOADING');
      try {
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindComputersByCloudProduct(
          cloudProductId,
        );
        commit('COMPUTERS_LOADED', response.data);
      } catch (error) {
        commit('COMPUTERS_LOAD_ERROR', error);
        logger.captureStoreError('loadComputers', error, { cloudProductId });
      }
    },

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    cloudProducts: (state: IAdminCloudProductsState): CloudProductSearchResponseDto[] => state.cloudProducts,
    cloudProductsLoading: (state: IAdminCloudProductsState): boolean => state.cloudProductsLoading,
    cloudProductsLoadError: (state: IAdminCloudProductsState): undefined | string => state.cloudProductsLoadError,
    organizationCloudProducts: (state: IAdminCloudProductsState): CloudProductInfoDto[] =>
      state.organizationAndUserCloudProducts,
    organizationCloudProductsLoading: (state: IAdminCloudProductsState): boolean =>
      state.organizationAndUserCloudProductsLoading,
    organizationCloudProductsLoadError: (state: IAdminCloudProductsState): undefined | string =>
      state.organizationAndUserCloudProductsLoadError,
    userCloudProducts: (state: IAdminCloudProductsState): CloudProductInfoDto[] =>
      state.organizationAndUserCloudProducts,
    userCloudProductsLoading: (state: IAdminCloudProductsState): boolean => state.userCloudProductsLoading,
    userCloudProductsLoadError: (state: IAdminCloudProductsState): undefined | string =>
      state.userCloudProductsLoadError,
    computers: (state: IAdminCloudProductsState): ComputerDto[] => state.computers,
    computersLoading: (state: IAdminCloudProductsState): boolean => state.computersLoading,
    computersLoadError: (state: IAdminCloudProductsState): undefined | string => state.computersLoadError,
    cloudProductChanging: (state: IAdminCloudProductsState): boolean => state.cloudProductChanging,
    assignCloudProductError: (state: IAdminCloudProductsState): undefined | string => state.assignCloudProductError,
    currentCloudProduct: (state: IAdminCloudProductsState): undefined | CloudProductInfoDto =>
      state.currentCloudProduct,
    currentCloudProductId: (state: IAdminCloudProductsState): undefined | number =>
      state.currentCloudProduct?.cloudProductId || undefined,
  },
};
