import { EditingUserDto, UserDto, UsersItemDto, UserUpdateDto } from '@/castapi';
import { uploadImage } from '@/store/modules/common/Files';
import { omit } from '@/shared/functions';
import { AppLogger } from '@/logger';
import {
  getBucketApi,
  getErrorMessage,
  getOrganizationsApi,
  getUsersApi,
  ISearchParams,
  omitUserSecretFields,
} from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminUsers state');

type IAdminUsersState = {
  users: UsersItemDto[];
  editingUser?: EditingUserDto;
  editingUserLoading: boolean;
  editingUserLoadError?: string;
  organizationCreating: boolean;
  organizationCreationError?: string;
  usersLoading: boolean;
  usersLoadError?: string;
  userChanging?: number;
  deleteUserError?: string;
  updateUserError?: string;
  resetTwoFaInProgress: boolean;
  resetTwoFaError?: string;
  passwordResetLinkCreating?: number;
  passwordResetLinkError?: string;
  passwordResetLink?: string;
};

const initialState = (): IAdminUsersState => ({
  users: [],
  editingUser: undefined,
  editingUserLoading: false,
  editingUserLoadError: undefined,
  organizationCreating: false,
  organizationCreationError: undefined,
  usersLoading: false,
  usersLoadError: undefined,
  userChanging: undefined,
  deleteUserError: undefined,
  updateUserError: undefined,
  resetTwoFaInProgress: false,
  resetTwoFaError: undefined,
  passwordResetLinkCreating: undefined,
  passwordResetLinkError: undefined,
  passwordResetLink: undefined,
});

type ActionParams = IActionParams<IAdminUsersState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    USERS_LOADING(state: IAdminUsersState): void {
      state.usersLoading = true;
      state.usersLoadError = undefined;
    },
    USERS_LOADED(state: IAdminUsersState, users: UsersItemDto[]): void {
      state.users = users;
      state.usersLoading = false;
    },
    USERS_LOAD_ERROR(state: IAdminUsersState, payload: Error): void {
      state.usersLoading = false;
      state.usersLoadError = getErrorMessage(payload);
    },
    EDITING_USER_LOADING(state: IAdminUsersState): void {
      state.editingUserLoading = true;
      state.editingUser = undefined;
      state.editingUserLoadError = undefined;
    },
    EDITING_USER_LOADED(state: IAdminUsersState, editingUser: EditingUserDto): void {
      state.editingUser = editingUser;
      state.editingUserLoading = false;
    },
    EDITING_USER_LOAD_ERROR(state: IAdminUsersState, payload: Error): void {
      state.editingUserLoadError = getErrorMessage(payload);
      state.editingUserLoading = false;
    },
    USER_CHANGING(state: IAdminUsersState, userId: number): void {
      state.userChanging = userId;
      // TODO
      // state.changeUserError = undefined;
    },
    USER_DELETED(state: IAdminUsersState): void {
      state.userChanging = undefined;
    },
    USER_UPDATED(state: IAdminUsersState): void {
      state.userChanging = undefined;
    },
    DELETE_USER_ERROR(state: IAdminUsersState, payload: Error): void {
      state.deleteUserError = getErrorMessage(payload);
      state.userChanging = undefined;
    },
    UPDATE_USER_ERROR(state: IAdminUsersState, payload: Error): void {
      state.updateUserError = getErrorMessage(payload);
      state.userChanging = undefined;
    },
    RESET_TWO_FA_IN_PROGRESS(state: IAdminUsersState): void {
      state.resetTwoFaInProgress = true;
      state.resetTwoFaError = undefined;
    },
    RESET_TWO_FA_SUCCESS(state: IAdminUsersState): void {
      state.resetTwoFaInProgress = false;
      state.resetTwoFaError = undefined;
    },
    RESET_TWO_FA_ERROR(state: IAdminUsersState, payload: Error): void {
      state.resetTwoFaInProgress = false;
      state.resetTwoFaError = getErrorMessage(payload);
    },
    PASSWORD_RESET_LINK_CREATING(state: IAdminUsersState, userId: number): void {
      state.passwordResetLinkCreating = userId;
      state.passwordResetLinkError = undefined;
      state.passwordResetLink = undefined;
    },
    PASSWORD_RESET_LINK_CREATED(state: IAdminUsersState, link: string): void {
      state.passwordResetLinkCreating = undefined;
      state.passwordResetLink = link;
    },
    PASSWORD_RESET_LINK_ERROR(state: IAdminUsersState, payload: Error): void {
      state.passwordResetLinkCreating = undefined;
      state.passwordResetLinkError = getErrorMessage(payload);
    },
    ORGANIZATION_CREATING(state: IAdminUsersState): void {
      // TODO
      // state.organizationCreateError = undefined;
      state.organizationCreating = true;
    },
    ORGANIZATION_CREATED(state: IAdminUsersState): void {
      state.organizationCreating = false;
    },
    ORGANIZATION_CREATION_ERROR(state: IAdminUsersState, payload: Error): void {
      state.organizationCreating = false;
      state.organizationCreationError = getErrorMessage(payload);
    },
    RESET_STATE(state: IAdminUsersState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadUsers({ commit, rootGetters }: ActionParams, searchParams: ISearchParams): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParams || {};
      commit('USERS_LOADING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerGetAllUsers(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('USERS_LOADED', response.data);
      } catch (error) {
        commit('USERS_LOAD_ERROR', error);
        logger.captureStoreError('loadUsers', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async loadEditingUser({ commit, rootGetters }: ActionParams, userId: number): Promise<void> {
      commit('EDITING_USER_LOADING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerGetEditingUser(userId);
        commit('EDITING_USER_LOADED', response.data);
      } catch (error) {
        commit('EDITING_USER_LOAD_ERROR', error);
        logger.captureStoreError('loadEditingUser', error, { userId });
      }
    },
    async removeUser({ commit, rootGetters }: ActionParams, userId: number): Promise<void> {
      commit('USER_CHANGING', userId);
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerDeleteUser({ userId });
        commit('USER_DELETED');
      } catch (error) {
        commit('DELETE_USER_ERROR', error);
        logger.captureStoreError('removeUser', error, { userId });
      }
    },
    async removeUserFromOrganization(
      { commit, rootGetters }: ActionParams,
      { userId, organizationId }: { userId: number; organizationId: number },
    ): Promise<void> {
      commit('USER_CHANGING', userId);
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerRemoveUserFromOrganization({
          userId,
          organizationId,
        });
        commit('USER_UPDATED');
      } catch (error) {
        commit('UPDATE_USER_ERROR', error);
        logger.captureStoreError('removeUserFromOrganization', error, { userId, organizationId });
      }
    },
    async updateUser(
      { commit, dispatch, rootGetters }: ActionParams,
      { user, organizationId, avatarImage }: { user: UserDto; organizationId: number; avatarImage: null | string },
    ): Promise<void> {
      commit('USER_CHANGING', user.userId);
      try {
        let avatar: null | string = null;
        const accessToken = rootGetters['login/accessToken'];
        if (avatarImage) {
          avatar = await uploadImage(avatarImage, `user-avatars/${user.userId}`, getBucketApi(accessToken));
        }

        let updateBody = omit(
          user,
          Object.keys(user).filter(f => !['firstName', 'lastName', 'phoneNumber', 'email', 'avatar'].includes(f)),
        ) as UserUpdateDto;
        if (avatarImage && avatar) {
          updateBody = { ...updateBody, avatar };
        }

        await getUsersApi(accessToken).usersControllerUpdate(user.userId, organizationId, updateBody);
        await dispatch('loadEditingUser', user.userId);
        commit('USER_UPDATED');
      } catch (error) {
        commit('UPDATE_USER_ERROR', error);
        const userToEdit = omitUserSecretFields(user);
        logger.captureStoreError('updateUser', error, { userToEdit, avatarImage });
      }
    },
    async resetTwoFa({ commit, dispatch, rootGetters }: ActionParams, userId: number): Promise<void> {
      commit('RESET_TWO_FA_IN_PROGRESS');
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerResetMobileTwoFa(userId);
        await dispatch('loadEditingUser', userId);
        commit('RESET_TWO_FA_SUCCESS');
      } catch (error) {
        commit('RESET_TWO_FA_ERROR', error);
        logger.captureStoreError('resetTwoFa', error, { userId });
      }
    },
    async createPasswordResetLink({ commit, rootGetters }: ActionParams, user: UserDto): Promise<void> {
      commit('PASSWORD_RESET_LINK_CREATING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerResetUserPassword(
          user.email,
        );
        commit('PASSWORD_RESET_LINK_CREATED', response.data);
      } catch (error) {
        commit('PASSWORD_RESET_LINK_ERROR', error);
        const userToEdit = omitUserSecretFields(user);
        logger.captureStoreError('createPasswordResetLink', error, { userToEdit });
      }
    },
    async createOrganizationForUser(
      { commit, dispatch, rootGetters }: ActionParams,
      { userId, organizationName, countryRef }: { userId: number; organizationName: string; countryRef: number },
    ): Promise<void> {
      commit('ORGANIZATION_CREATING');
      try {
        await getOrganizationsApi(rootGetters['login/accessToken']).organizationsControllerCreateOrganization({
          userId,
          organizationName,
          countryRef,
        });
        commit('ORGANIZATION_CREATED');
        await dispatch('loadEditingUser', userId);
      } catch (error) {
        commit('ORGANIZATION_CREATION_ERROR', error);
        logger.captureStoreError('createOrganizationForUser', error, { userId, organizationName, countryRef });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    users: (state: IAdminUsersState): UsersItemDto[] => state.users,
    usersLoading: (state: IAdminUsersState): boolean => state.usersLoading,
    usersLoadError: (state: IAdminUsersState): undefined | string => state.usersLoadError,
    // userChanging: (state: IAdminUsersState) => state.userChanging,
    deleteUserError: (state: IAdminUsersState): undefined | string => state.deleteUserError,
    updateUserError: (state: IAdminUsersState): undefined | string => state.updateUserError,
    // passwordResetLinkCreating: (state: IAdminUsersState) => state.passwordResetLinkCreating,
    passwordResetLinkError: (state: IAdminUsersState): undefined | string => state.passwordResetLinkError,
    passwordResetLink: (state: IAdminUsersState): undefined | string => state.passwordResetLink,
    editingUser: (state: IAdminUsersState): undefined | EditingUserDto => state.editingUser,
    editingUserId: (state: IAdminUsersState): undefined | number => state.editingUser?.userId,
    editingUserLoading: (state: IAdminUsersState): boolean => state.editingUserLoading,
    editingUserOrganizationId: (state: IAdminUsersState): undefined | number => state.editingUser?.organizationId,
    // TODO
    // editingUserOrganizationName: (state: IAdminUsersState) => state.editingUser?.editingUserOrganizationName,
    organizationCreationError: (state: IAdminUsersState): undefined | string => state.organizationCreationError,
    resetTwoFaInProgress: (state: IAdminUsersState): boolean => state.resetTwoFaInProgress,
    resetTwoFaError: (state: IAdminUsersState): undefined | string => state.resetTwoFaError,
  },
};
