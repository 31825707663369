import {
  CloudProductInfoDto,
  DongleActivityDto,
  DongleLicenseDto,
  DongleReauthCodeDto,
  DongleUpgradeDto,
  DongleUsersInfoDto,
  InsertDongleByAdminDto,
  ProductInfoDto,
  RenewLeaseDto,
  RenewMembershipDto,
  SteppingDto,
  UpgradeTypeDto,
} from '@/castapi';
import { getDonglesApi, getErrorMessage, getSteppingsApi, ISearchParams } from '@/castapi/helpers';
import { AppLogger } from '@/logger';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminDongles state');

interface IAdminDonglesState {
  dongles: DongleUsersInfoDto[];
  availableDongles: ProductInfoDto[];
  availableDonglesLoading: boolean;
  availableDonglesLoadError?: string;
  organizationAndUserDongles?: ProductInfoDto[];
  steppingsList: SteppingDto[];
  steppingsLoading: boolean;
  steppingsLoadError?: string;
  allDonglesLoading: boolean;
  allDonglesLoadError?: string;
  organizationAndUserDonglesLoading: boolean;
  organizationAndUserDonglesLoadError?: string;
  insertDongleError?: string;
  assignDongleError?: string;
  dongleTransferInProgress: boolean;
  dongleTransferError?: string;
  dongleDeleteInProgress: boolean;
  dongleDeleteError?: string;
  statusChangeError?: string;
  notesChangeError?: string;
  dongleChanging: boolean;
  renewCode?: string;
  upgradeCode?: string;
  upgradeTypes: UpgradeTypeDto[];
  currentDongle?: ProductInfoDto | CloudProductInfoDto;
  currentDongleChanging: boolean;
  currentDongleChangeError?: string;
  dongleActivitiesLoading: boolean;
  dongleActivitiesLoadError?: string;
  dongleActivities: DongleActivityDto[];
  dongleLicensesLoading: boolean;
  dongleLicensesLoadError?: string;
  dongleLicenses: DongleLicenseDto[];
  isRenewUpgrade: true;
  generatingDongleUpgradeCode: boolean;
  generateMembershipLoading: boolean;
  userDongles: [];
  userDonglesLoading: boolean;
  userDonglesLoadError?: string;
  dongleReauthData?: DongleReauthCodeDto;
  dongleReauthDataLoading: boolean;
  dongleReauthDataLoadError?: string;
  cheatCounterResetError?: string;
  renewUpgradeCodeGenerationError?: string;
}

const initialState = (): IAdminDonglesState => ({
  dongles: [],
  availableDongles: [],
  availableDonglesLoading: false,
  availableDonglesLoadError: undefined,
  organizationAndUserDongles: [],
  steppingsList: [],
  steppingsLoading: false,
  steppingsLoadError: undefined,
  allDonglesLoading: false,
  allDonglesLoadError: undefined,
  organizationAndUserDonglesLoading: false,
  organizationAndUserDonglesLoadError: undefined,
  insertDongleError: undefined,
  generatingDongleUpgradeCode: false,
  generateMembershipLoading: false,
  assignDongleError: undefined,
  dongleTransferInProgress: false,
  dongleTransferError: undefined,
  dongleDeleteInProgress: false,
  dongleDeleteError: undefined,
  statusChangeError: undefined,
  notesChangeError: undefined,
  dongleChanging: false,
  renewCode: undefined,
  upgradeCode: undefined,
  upgradeTypes: [],
  currentDongle: undefined,
  currentDongleChanging: false,
  currentDongleChangeError: undefined,
  dongleActivitiesLoading: false,
  dongleActivitiesLoadError: undefined,
  dongleActivities: [],
  isRenewUpgrade: true,
  userDongles: [],
  userDonglesLoading: false,
  userDonglesLoadError: undefined,
  dongleReauthData: undefined,
  dongleReauthDataLoading: false,
  dongleReauthDataLoadError: undefined,
  cheatCounterResetError: undefined,
  renewUpgradeCodeGenerationError: undefined,
  dongleLicenses: [],
  dongleLicensesLoading: false,
  dongleLicensesLoadError: undefined,
});

interface SerialData {
  code: string;
  serialNumber: string;
}

type ActionParams = IActionParams<IAdminDonglesState>;

// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SELECT_DONGLE(state: IAdminDonglesState, dongle: ProductInfoDto | CloudProductInfoDto): void {
      state.currentDongle = dongle;
    },
    STEPPINGS_LOADING(state: IAdminDonglesState): void {
      state.steppingsLoadError = undefined;
      state.steppingsLoading = true;
    },
    STEPPINGS_LOADED(state: IAdminDonglesState, payload: SteppingDto[]): void {
      state.steppingsList = payload;
      state.steppingsLoadError = undefined;
    },
    STEPPINGS_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.steppingsLoadError = getErrorMessage(error);
      state.steppingsLoading = false;
    },
    DONGLES_LOADING(state: IAdminDonglesState): void {
      state.allDonglesLoadError = undefined;
      state.allDonglesLoading = true;
    },
    DONGLES_LOADED(state: IAdminDonglesState, payload: DongleUsersInfoDto[]): void {
      state.dongles = payload;
      state.allDonglesLoading = false;
    },
    DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.allDonglesLoadError = getErrorMessage(error);
      state.allDonglesLoading = false;
    },
    AVAILABLE_DONGLES_LOADING(state: IAdminDonglesState): void {
      state.availableDonglesLoadError = undefined;
      state.availableDonglesLoading = true;
    },
    AVAILABLE_DONGLES_LOADED(state: IAdminDonglesState, payload: ProductInfoDto[]): void {
      state.availableDongles = payload;
      state.availableDonglesLoading = false;
    },
    AVAILABLE_DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.availableDonglesLoadError = getErrorMessage(error);
      state.availableDonglesLoading = false;
    },
    ORGANIZATION_DONGLES_LOADING(state: IAdminDonglesState): void {
      state.organizationAndUserDonglesLoadError = undefined;
      state.organizationAndUserDonglesLoading = true;
    },
    ORGANIZATION_DONGLES_LOADED(state: IAdminDonglesState, payload: ProductInfoDto[]): void {
      state.organizationAndUserDongles = payload;
      state.organizationAndUserDonglesLoading = false;
    },
    ORGANIZATION_DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.organizationAndUserDonglesLoadError = getErrorMessage(error);
      state.organizationAndUserDonglesLoading = false;
    },
    USER_DONGLES_LOADING(state: IAdminDonglesState): void {
      state.userDonglesLoadError = undefined;
      state.userDonglesLoading = true;
    },
    USER_DONGLES_LOADED(state: IAdminDonglesState, payload: ProductInfoDto[]): void {
      state.organizationAndUserDongles = payload;
      state.userDonglesLoading = false;
    },
    USER_DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.userDonglesLoadError = getErrorMessage(error);
      state.userDonglesLoading = false;
    },
    INSERT_DONGLE_ERROR(state: IAdminDonglesState, error: Error): void {
      state.insertDongleError = getErrorMessage(error);
    },
    ASSIGN_DONGLE_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.assignDongleError = getErrorMessage(payload);
    },
    DONGLE_TRANSFER_IN_PROGRESS(state: IAdminDonglesState): void {
      state.dongleTransferError = undefined;
      state.dongleTransferInProgress = true;
    },
    DONGLE_TRANSFER_SUCCESS(state: IAdminDonglesState): void {
      state.dongleTransferInProgress = false;
    },
    DONGLE_TRANSFER_ERROR(state: IAdminDonglesState, error: Error): void {
      state.dongleTransferError = getErrorMessage(error);
      state.dongleTransferInProgress = false;
    },
    DONGLE_DELETE_IN_PROGRESS(state: IAdminDonglesState): void {
      state.dongleDeleteError = undefined;
      state.dongleDeleteInProgress = true;
    },
    DONGLE_DELETE_SUCCESS(state: IAdminDonglesState): void {
      state.dongleDeleteInProgress = false;
    },
    DONGLE_DELETE_ERROR(state: IAdminDonglesState, error: Error): void {
      state.dongleDeleteInProgress = false;
      state.dongleDeleteError = getErrorMessage(error);
    },
    CHANGE_DONGLE_STATUS_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.statusChangeError = getErrorMessage(payload);
    },
    UPDATE_DONGLE_NOTES_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.notesChangeError = getErrorMessage(payload);
    },
    CHEAT_COUNTER_RESET_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.cheatCounterResetError = getErrorMessage(payload);
    },
    DONGLE_CHANGING(state: IAdminDonglesState): void {
      state.assignDongleError = undefined;
      state.statusChangeError = undefined;
      state.notesChangeError = undefined;
      state.cheatCounterResetError = undefined;
      state.renewUpgradeCodeGenerationError = undefined;
      state.dongleChanging = true;
    },
    GENERATING_UPGRADE_CODE(state: IAdminDonglesState): void {
      state.generatingDongleUpgradeCode = true;
    },
    GENERATED_UPGRADE_CODE(state: IAdminDonglesState): void {
      state.generatingDongleUpgradeCode = false;
    },
    GENERATING_MEMBERSHIP(state: IAdminDonglesState): void {
      state.generateMembershipLoading = true;
    },
    MEMBERSHIP_GENERATED(state: IAdminDonglesState): void {
      state.generateMembershipLoading = false;
    },
    DONGLE_CHANGED(state: IAdminDonglesState): void {
      state.dongleChanging = false;
    },
    GET_RENEW_MEMBERSHIP_CODE(state: IAdminDonglesState, data: SerialData): void {
      state.renewCode = data.code;
      if (!state.organizationAndUserDongles) {
        throw new Error('organizationAndUserDongles is not defined');
      }
      const dongleIndex = state.organizationAndUserDongles.findIndex(d => d.serialNumber === data.serialNumber);
      state.organizationAndUserDongles[dongleIndex].renewCode = data.code;
    },
    GET_UPGRADE_CODE(state: IAdminDonglesState, data: SerialData): void {
      state.upgradeCode = data.code;
      if (!state.organizationAndUserDongles) {
        throw new Error('organizationAndUserDongles is not defined');
      }
      const dongleIndex = state.organizationAndUserDongles.findIndex(d => d.serialNumber === data.serialNumber);
      state.organizationAndUserDongles[dongleIndex].upgradeCode = data.code;
    },
    GET_UPGRADE_TYPES(state: IAdminDonglesState, payload: []): void {
      state.upgradeTypes = payload;
    },
    DONGLE_ACTIVITIES_LOADING(state: IAdminDonglesState): void {
      state.dongleActivities = [];
      state.dongleActivitiesLoadError = undefined;
      state.dongleActivitiesLoading = true;
    },
    DONGLE_ACTIVITIES_LOADED(state: IAdminDonglesState, activities: DongleActivityDto[]): void {
      state.dongleActivities = activities;
      state.dongleActivitiesLoading = false;
    },
    DONGLE_ACTIVITIES_LOAD_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleActivitiesLoading = false;
      state.dongleActivitiesLoadError = getErrorMessage(payload);
    },
    DONGLE_LICENSES_LOADING(state: IAdminDonglesState): void {
      state.dongleLicenses = [];
      state.dongleLicensesLoadError = undefined;
      state.dongleLicensesLoading = true;
    },
    DONGLE_LICENSES_LOADED(state: IAdminDonglesState, activities: DongleLicenseDto[]): void {
      state.dongleLicenses = activities;
      state.dongleLicensesLoading = false;
    },
    DONGLE_LICENSES_LOAD_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleLicensesLoading = false;
      state.dongleLicensesLoadError = getErrorMessage(payload);
    },
    DONGLE_REAUTH_DATA_LOADING(state: IAdminDonglesState): void {
      state.dongleReauthData = undefined;
      state.dongleReauthDataLoadError = undefined;
      state.dongleReauthDataLoading = true;
    },
    DONGLE_REAUTH_DATA_LOADED(state: IAdminDonglesState, data: DongleReauthCodeDto): void {
      state.dongleReauthData = data;
      state.dongleReauthDataLoading = false;
    },
    DONGLE_REAUTH_DATA_LOAD_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleReauthDataLoading = false;
      state.dongleReauthDataLoadError = getErrorMessage(payload);
    },
    DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.renewUpgradeCodeGenerationError = getErrorMessage(payload);
    },
    RESET_STATE(state: IAdminDonglesState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        // TODO: Figure out with problem 'computed readonly'. Case /donsles -> /organizations/18 -> /dongles
        if (key === 'currentDongle') {
          return;
        }
        state[key] = initState[key];
      });
    },
    CLEAR_ORGANIZATION_DONGLES(state: IAdminDonglesState): void {
      state.organizationAndUserDongles = undefined;
    },
  },
  actions: {
    async loadDongles({ commit, rootGetters }: ActionParams, searchParams: ISearchParams): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParams || {};
      commit('DONGLES_LOADING', { limit, offset, searchText });
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAll(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('DONGLES_LOADED', response.data);
      } catch (error) {
        commit('DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async loadSteppings({ state, commit, rootGetters }: ActionParams): Promise<void> {
      if (state.steppingsList.length) {
        return;
      }
      try {
        // commit('RESET_STATE');
        commit('STEPPINGS_LOADING');
        const response = await getSteppingsApi(rootGetters['login/accessToken']).steppingControllerFindAll();
        commit('STEPPINGS_LOADED', response.data);
      } catch (error) {
        commit('STEPPINGS_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error);
      }
    },
    async loadAvailableDongles(
      { commit, rootGetters, state }: ActionParams,
      {
        offset,
        limit,
        searchText,
        productLineId,
        productLevel,
        licenseTypeId,
      }: ISearchParams & {
        productLineId: number;
        productLevel: number;
        licenseTypeId: number;
      },
    ): Promise<void> {
      try {
        // commit('RESET_STATE');
        commit('AVAILABLE_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllAvailable(
          limit,
          offset,
          searchText,
          undefined,
          undefined,
          productLineId,
          productLevel,
          licenseTypeId,
        );
        commit('AVAILABLE_DONGLES_LOADED', [...(offset ? state.availableDongles : []), ...response.data]);
      } catch (error) {
        commit('AVAILABLE_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadAvailableDongles', error);
      }
    },
    async loadOrganizationDongles({ commit, rootGetters }: ActionParams, data: number): Promise<void> {
      try {
        // commit('RESET_STATE');
        commit('ORGANIZATION_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByOrganization(
          data,
        );
        commit('ORGANIZATION_DONGLES_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATION_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizationDongles', error, { data });
      }
    },
    async loadUserDongles({ commit, rootGetters }: ActionParams, data: number): Promise<void> {
      try {
        // commit('RESET_STATE');
        commit('USER_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByUser(data);
        commit('USER_DONGLES_LOADED', response.data);
      } catch (error) {
        commit('USER_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadUserDongles', error, { data });
      }
    },
    async loadVisibleDongles({ dispatch, rootGetters }: ActionParams, fromPage: string): Promise<void> {
      if (fromPage === 'organizations') {
        const organizationId = rootGetters['adminOrganizations/currentOrganizationId'];
        await dispatch('loadOrganizationDongles', organizationId);
      } else if (fromPage === 'user') {
        const userId = rootGetters['adminUsers/editingUserId'];
        await dispatch('loadUserDongles', userId);
      }
    },
    async insertDongle({ commit, rootGetters }: ActionParams, data: InsertDongleByAdminDto): Promise<void> {
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerInsertDongleByAdmin(data);
      } catch (error) {
        commit('INSERT_DONGLE_ERROR', error);
        logger.captureStoreError('insertDongle', error, { data });
      }
    },
    async assignDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        dongleId,
        userId,
        fromPage,
      }: {
        dongleId: number;
        userId: number;
        fromPage: string;
      },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_CHANGING');
        await getDonglesApi(accessToken).donglesControllerAssignDongle({ dongleId, userId });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('ASSIGN_DONGLE_ERROR', error);
        logger.captureStoreError('assignDongle', error, { dongleId, userId, fromPage });
      }
    },
    async unAssignDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, fromPage }: { dongleId: number; fromPage: string },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_CHANGING');
        await getDonglesApi(accessToken).donglesControllerUnAssignDongle({ dongleId });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('ASSIGN_DONGLE_ERROR', error);
        logger.captureStoreError('unAssignDongle', error, { dongleId, fromPage });
      }
    },
    async transferDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, email, fromPage }: { dongleId: number; email: string; fromPage: string },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_TRANSFER_IN_PROGRESS');
        await getDonglesApi(accessToken).donglesControllerTransferDongle({ dongleId, email });
        commit('DONGLE_TRANSFER_SUCCESS');
        await dispatch('loadVisibleDongles', fromPage);
      } catch (error) {
        commit('DONGLE_TRANSFER_ERROR', error);
        logger.captureStoreError('transferDongle', error, { dongleId, fromPage });
      }
    },
    async removeDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, fromPage }: { dongleId: number; fromPage: string },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_DELETE_IN_PROGRESS');
        await getDonglesApi(accessToken).donglesControllerRemoveDongle({ dongleId });
        commit('DONGLE_DELETE_SUCCESS');
        await dispatch('loadVisibleDongles', fromPage);
      } catch (error) {
        commit('DONGLE_DELETE_ERROR', error);
        logger.captureStoreError('removeDongle', error, { dongleId, fromPage });
      }
    },
    async changeDongleStatus(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        isLoaned,
        isRetired,
        dongleId,
        fromPage,
      }: {
        isLoaned: boolean;
        isRetired: boolean;
        dongleId: number;
        fromPage: string;
      },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerChangeDongleStatus({
          isLoaned,
          isRetired,
          dongleId,
        });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHANGE_DONGLE_STATUS_ERROR', error);
        logger.captureStoreError('changeDongleStatus', error, { isLoaned, isRetired, dongleId, fromPage });
      }
    },
    async updateDongleNotes(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, notes, fromPage }: { dongleId: number; notes: string; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerUpdateDongleNotes({ dongleId, notes });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHANGE_DONGLE_STATUS_ERROR', error);
        logger.captureStoreError('updateDongleNotes', error, { dongleId, notes, fromPage });
      }
    },
    async getRenewMembershipCode(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        data,
        fromPage,
      }: {
        data: RenewMembershipDto;
        fromPage: string;
      },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRenewMembership(data);
        commit('DONGLE_CHANGED');
        commit('GET_RENEW_MEMBERSHIP_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getRenewMembershipCode', error, { data, fromPage });
      }
    },
    async getRenewLeaseCode(
      { commit, dispatch, rootGetters }: ActionParams,
      { data, fromPage }: { data: RenewLeaseDto; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      commit('GENERATING_MEMBERSHIP');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRenewLease(data);
        commit('DONGLE_CHANGED');
        commit('MEMBERSHIP_GENERATED');
        commit('GET_RENEW_MEMBERSHIP_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getRenewLeaseCode', error, { data, fromPage });
      }
    },
    async getUpgradeCode(
      { commit, dispatch, rootGetters }: ActionParams,
      { data, fromPage }: { data: DongleUpgradeDto; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      commit('GENERATING_UPGRADE_CODE');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetUpgradeCode(data);
        commit('DONGLE_CHANGED');
        commit('GENERATED_UPGRADE_CODE');
        commit('GET_UPGRADE_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getUpgradeCode', error, { data, fromPage });
      }
    },
    async getUpgradeTypes({ commit, rootGetters }: ActionParams, data: number): Promise<void> {
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetUpgradeTypes(data);
        commit('GET_UPGRADE_TYPES', response.data);
      } catch (error) {
        logger.captureStoreError('getUpgradeTypes', error, { data });
      }
    },
    async selectDongle(
      { commit, dispatch }: ActionParams,
      dongle: ProductInfoDto | CloudProductInfoDto,
    ): Promise<void> {
      commit('SELECT_DONGLE', dongle);
      if ((dongle as ProductInfoDto).dongleId) {
        const dongleId = (dongle as ProductInfoDto).dongleId;
        await dispatch('loadDongleActivities', dongleId);
        await dispatch('loadDongleLicenses', dongleId);
        await dispatch('loadDongleReauthData', dongleId);
      } else {
        // TODO: Implement for CloudProductInfoDto
      }
    },
    async loadDongleActivities({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_ACTIVITIES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleActivities(
          dongleId,
        );
        commit('DONGLE_ACTIVITIES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_ACTIVITIES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleActivities', error, { dongleId });
      }
    },
    async loadDongleLicenses({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_LICENSES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetLearnDongleLicenses(
          dongleId,
        );
        commit('DONGLE_LICENSES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_LICENSES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleLicenses', error, { dongleId });
      }
    },
    async loadDongleReauthData({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_REAUTH_DATA_LOADING');
      try {
        const response = await getDonglesApi(
          rootGetters['login/accessToken'],
        ).donglesControllerGetDongleLatestReauthCode(dongleId);
        commit('DONGLE_REAUTH_DATA_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_REAUTH_DATA_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleReauthData', error, { dongleId });
      }
    },
    async resetDongleCheatCounter(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, fromPage }: { dongleId: number; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerResetDongleCheatCounter(dongleId);
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHEAT_COUNTER_RESET_ERROR', error);
        logger.captureStoreError('resetDongleCheatCounter', error, { dongleId, fromPage });
      }
    },

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    dongles: (state: IAdminDonglesState): DongleUsersInfoDto[] => state.dongles,
    steppingsList: (state: IAdminDonglesState): SteppingDto[] => state.steppingsList,
    availableDongles: (state: IAdminDonglesState): ProductInfoDto[] => state.availableDongles,
    organizationDongles: (state: IAdminDonglesState): undefined | ProductInfoDto[] => state.organizationAndUserDongles,
    allDonglesLoading: (state: IAdminDonglesState): boolean => state.allDonglesLoading,
    allDonglesLoadError: (state: IAdminDonglesState): undefined | string => state.allDonglesLoadError,
    availableDonglesLoading: (state: IAdminDonglesState): boolean => state.availableDonglesLoading,
    availableDonglesLoadError: (state: IAdminDonglesState): undefined | string => state.availableDonglesLoadError,
    organizationDonglesLoading: (state: IAdminDonglesState): boolean => state.organizationAndUserDonglesLoading,
    organizationDonglesLoadError: (state: IAdminDonglesState): undefined | string =>
      state.organizationAndUserDonglesLoadError,
    insertDongleError: (state: IAdminDonglesState): undefined | string => state.insertDongleError,
    // dongleDeleteInProgress: (state: IAdminDonglesState) => state.dongleDeleteInProgress,
    // dongleDeleteError: (state: IAdminDonglesState) => state.dongleDeleteError,
    dongleTransferInProgress: (state: IAdminDonglesState): boolean => state.dongleTransferInProgress,
    dongleTransferError: (state: IAdminDonglesState): undefined | string => state.dongleTransferError,
    dongleChanging: (state: IAdminDonglesState): boolean => state.dongleChanging,
    generatingDongleUpgradeCode: (state: IAdminDonglesState): boolean => state.generatingDongleUpgradeCode,
    generateMembershipLoading: (state: IAdminDonglesState): boolean => state.generateMembershipLoading,
    statusChangeError: (state: IAdminDonglesState): undefined | string => state.statusChangeError,
    notesChangeError: (state: IAdminDonglesState): undefined | string => state.notesChangeError,
    upgradeTypes: (state: IAdminDonglesState): UpgradeTypeDto[] => state.upgradeTypes,
    // currentDongle: (state: IAdminDonglesState) => state.currentDongle,
    currentDongleId: (state: IAdminDonglesState): number => {
      if ((state.currentDongle as ProductInfoDto).dongleId) {
        return (state.currentDongle as ProductInfoDto).dongleId;
      }
      return (state.currentDongle as CloudProductInfoDto).cloudProductId;
    },
    dongleActivities: (state: IAdminDonglesState): DongleActivityDto[] => state.dongleActivities,
    dongleActivitiesLoading: (state: IAdminDonglesState): boolean => state.dongleActivitiesLoading,
    dongleActivitiesLoadError: (state: IAdminDonglesState): undefined | string => state.dongleActivitiesLoadError,
    userDongles: (state: IAdminDonglesState): undefined | ProductInfoDto[] => state.organizationAndUserDongles,
    userDonglesLoading: (state: IAdminDonglesState): boolean => state.userDonglesLoading,
    userDonglesLoadError: (state: IAdminDonglesState): undefined | string => state.userDonglesLoadError,
    dongleReauthData: (state: IAdminDonglesState): undefined | DongleReauthCodeDto => state.dongleReauthData,
    dongleReauthDataLoading: (state: IAdminDonglesState): boolean => state.dongleReauthDataLoading,
    dongleReauthDataLoadError: (state: IAdminDonglesState): undefined | string => state.dongleReauthDataLoadError,
    cheatCounterResetError: (state: IAdminDonglesState): undefined | string => state.cheatCounterResetError,
    renewUpgradeCodeGenerationError: (state: IAdminDonglesState): undefined | string =>
      state.renewUpgradeCodeGenerationError,
    dongleDeleteError: (state: IAdminDonglesState): undefined | string => state.dongleDeleteError,
    dongleLicenses: (state: IAdminDonglesState): DongleLicenseDto[] => state.dongleLicenses,
  },
};
