import { getCloudApi, getErrorMessage } from '@/castapi/helpers';
import { CloudProductInfoDto, ComputerDto } from '@/castapi';
import { AppLogger } from '@/logger';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('cloudProducts state');

interface ICloudProductsState {
  cloudProducts: CloudProductInfoDto[];
  cloudProductsLoading: boolean;
  cloudProductsLoadError?: string;
  currentCloudProduct?: CloudProductInfoDto;
  currentCloudProductsChanging: boolean;
  currentCloudProductChangeError?: string;
  computers: ComputerDto[];
  computersLoading: boolean;
  computersLoadError?: string;
  computerUpdating: boolean;
  computerUpdateError?: string;
  currentCloudProductChanging: boolean;
  currentDongleChangeError?: string;
  currentDongleChanging: boolean;
}

const initialState: () => ICloudProductsState = () => ({
  cloudProducts: [],
  cloudProductsLoading: false,
  cloudProductsLoadError: undefined,
  currentCloudProduct: undefined,
  currentCloudProductsChanging: false,
  currentCloudProductChangeError: undefined,
  computers: [],
  computersLoading: false,
  computersLoadError: undefined,
  computerUpdating: false,
  computerUpdateError: undefined,
  currentCloudProductChanging: false,
  currentDongleChangeError: undefined,
  currentDongleChanging: false,
});

type ActionParams = IActionParams<ICloudProductsState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    CLOUD_PRODUCTS_LOADING(state: ICloudProductsState): void {
      state.cloudProductsLoadError = undefined;
      state.cloudProductsLoading = true;
    },

    CLOUD_PRODUCTS_LOADED(state: ICloudProductsState, payload: CloudProductInfoDto[]): void {
      state.cloudProducts = payload;
      state.cloudProductsLoading = false;
      state.currentCloudProduct =
        state.cloudProducts.find(
          cloudProduct => cloudProduct.cloudProductId === state.currentCloudProduct?.cloudProductId,
        ) || undefined;
    },

    CLOUD_PRODUCTS_LOAD_ERROR(state: ICloudProductsState, payload: Error): void {
      state.cloudProductsLoadError = getErrorMessage(payload);
      state.cloudProductsLoading = false;
    },

    CLOUD_PRODUCT_CHANGING(state: ICloudProductsState): void {
      state.currentCloudProductChanging = true;
      state.currentDongleChangeError = undefined;
    },

    CLOUD_PRODUCT_CHANGED(state: ICloudProductsState): void {
      state.currentDongleChanging = false;
    },

    CLOUD_PRODUCT_CHANGE_ERROR(state: ICloudProductsState, payload: Error): void {
      state.currentDongleChangeError = getErrorMessage(payload);
      state.currentDongleChanging = false;
    },

    SELECT_CLOUD_PRODUCT(state: ICloudProductsState, cloudProduct: CloudProductInfoDto): void {
      state.currentCloudProduct = cloudProduct;
    },

    COMPUTERS_LOADING(state: ICloudProductsState): void {
      state.computers = [];
      state.computersLoadError = undefined;
      state.computersLoading = true;
    },

    COMPUTERS_LOADED(state: ICloudProductsState, computers: ComputerDto[]): void {
      state.computers = computers;
      state.computersLoading = false;
    },

    COMPUTERS_LOAD_ERROR(state: ICloudProductsState, payload: Error): void {
      state.computersLoading = false;
      state.computersLoadError = getErrorMessage(payload);
    },

    COMPUTER_UPDATING(state: ICloudProductsState): void {
      state.computerUpdating = true;
      state.computerUpdateError = undefined;
    },

    COMPUTER_UPDATED(state: ICloudProductsState): void {
      state.computerUpdating = false;
    },

    COMPUTER_UPDATE_ERROR(state: ICloudProductsState, payload: Error): void {
      state.computerUpdateError = getErrorMessage(payload);
      state.computerUpdating = false;
    },

    RESET_STATE(state: ICloudProductsState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadCloudProducts({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('CLOUD_PRODUCTS_LOADING');
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindAllByOrganization(
          rootGetters['organizations/organizationId'],
        );
        commit('CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadCloudProducts', error);
      }
    },

    async loadComputers({ commit, rootGetters }: ActionParams, cloudProductId: number): Promise<void> {
      commit('COMPUTERS_LOADING');
      try {
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindComputersByCloudProduct(
          cloudProductId,
        );
        commit('COMPUTERS_LOADED', response.data);
      } catch (error) {
        commit('COMPUTERS_LOAD_ERROR', error);
        logger.captureStoreError('loadComputers', error, { cloudProductId });
      }
    },

    async assignCloudProduct(
      { commit, dispatch, rootGetters }: ActionParams,
      { cloudProductId, userId }: { cloudProductId: number; userId: number },
    ): Promise<void> {
      commit('CLOUD_PRODUCT_CHANGING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerAssignCloudProduct({
          cloudProductId,
          userId,
        });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadCloudProducts');
      } catch (error) {
        commit('CLOUD_PRODUCT_CHANGE_ERROR', error);
        logger.captureStoreError('assignCloudProduct', error, { cloudProductId, userId });
      }
    },

    async unAssignCloudProduct({ commit, dispatch, rootGetters }: ActionParams, cloudProductId: number): Promise<void> {
      commit('CLOUD_PRODUCT_CHANGING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerUnAssignCloudProduct({ cloudProductId });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadCloudProducts');
      } catch (error) {
        commit('CLOUD_PRODUCT_CHANGE_ERROR', error);
        logger.captureStoreError('unAssignCloudProduct', error, { cloudProductId });
      }
    },

    async updateComputer(
      { commit, dispatch, rootGetters }: ActionParams,
      { computerId, name }: { computerId: number; name: string },
    ): Promise<void> {
      commit('COMPUTER_UPDATING');
      try {
        console.log('olha o computerId');
        console.log(computerId);
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerUpdateComputer({
          computerId,
          name,
        });
        commit('COMPUTER_UPDATED');
        await dispatch('loadCloudProducts');
      } catch (error) {
        commit('COMPUTER_UPDATE_ERROR', error);
        logger.captureStoreError('updateComputer', error, { computerId, name });
      }
    },

    async deleteComputer({ commit, dispatch, rootGetters }: ActionParams, machineId: number): Promise<void> {
      commit('COMPUTER_UPDATING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerDeleteComputer({
          machineId,
        });
        commit('COMPUTER_UPDATED');
        await dispatch('loadCloudProducts');
      } catch (error) {
        commit('COMPUTER_UPDATE_ERROR', error);
        logger.captureStoreError('updateComputer', error, { machineId, name });
      }
    },

    async changeAutoRenew(
      { commit, dispatch, rootGetters }: ActionParams,
      data: {
        cloudProductId: number;
        autoRenew: boolean;
      },
    ): Promise<void> {
      const { cloudProductId, autoRenew } = data;
      console.log({ cloudProductId, autoRenew });
      commit('CLOUD_PRODUCTS_LOADING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerChangeAutoRenew({
          cloudProductId,
          autoRenew,
        });
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadCloudProducts', error);
      }
      await dispatch('loadCloudProducts');
    },

    async dropRenewalDate({ commit, dispatch, rootGetters }: ActionParams, cloudProductId: number): Promise<void> {
      commit('CLOUD_PRODUCTS_LOADING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerDropRenewalDate({
          cloudProductId,
        });
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('dropRenewalDate', error);
      }
      await dispatch('loadCloudProducts');
    },

    async dropExpiryDate({ commit, dispatch, rootGetters }: ActionParams, cloudProductId: number): Promise<void> {
      commit('CLOUD_PRODUCTS_LOADING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerDropExpiryDate({
          cloudProductId,
        });
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('dropExpiryDate', error);
      }
      await dispatch('loadCloudProducts');
    },

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
  },
  getters: {
    cloudProducts: (state: ICloudProductsState): CloudProductInfoDto[] => state.cloudProducts,
    cloudProductsLoading: (state: ICloudProductsState): boolean => state.cloudProductsLoading,
    cloudProductsLoadError: (state: ICloudProductsState): undefined | string => state.cloudProductsLoadError,
    computers: (state: ICloudProductsState): ComputerDto[] => state.computers,
    computersLoading: (state: ICloudProductsState): boolean => state.computersLoading,
    computersLoadError: (state: ICloudProductsState): undefined | string => state.computersLoadError,
    currentCloudProduct: (state: ICloudProductsState): undefined | CloudProductInfoDto => state.currentCloudProduct,
    currentCloudProductLicenseCode: (state: ICloudProductsState): undefined | string =>
      state.currentCloudProduct?.licenseCode,
    currentCloudProductId: (state: ICloudProductsState): undefined | number =>
      state.currentCloudProduct?.cloudProductId,
    computerUpdateError: (state: ICloudProductsState): undefined | string => state.computerUpdateError,
  },
};
