<template>
  <v-avatar :size="`${size}px`" class="avatar" :style="avatarStyle">
    <img v-if="getImg" alt="Avatar" :src="getImg" @error="onImageError()" width="64" height="64" />
    <span v-else-if="fullName" class="avatar-initials" :style="initialsSpanStyle">{{ initials || '?' }}</span>
    <v-icon v-else large>mdi-account-circle</v-icon>
    <slot />
  </v-avatar>
</template>

<script setup lang="ts">
import { ref, defineProps, withDefaults, computed } from 'vue';
import { concatInitials, getBgColor, getColor } from '@/shared/functions';

const props = withDefaults(defineProps<{ img?: string; fullName?: string; size?: number }>(), {
  img: undefined,
  fullName: undefined,
  size: 36,
});
const error = ref<undefined | string>(undefined);
const getImg = computed<undefined | string>(() => (error.value ? undefined : props.img));
const initials = computed<undefined | string>(() => props.fullName && concatInitials(props.fullName));
const initialsSpanStyle = computed<Record<string, string>>(() => ({
  'font-size': `${Math.floor(props.size / 2.5)}px`,
  'line-height': `${props.size}px`,
  color: getColor(props.fullName),
}));
const avatarStyle = computed<Record<string, string>>(() => ({
  'background-color': getBgColor(props.fullName),
  border: getBgColor(props.fullName) === '#FFFFFF' ? '1px solid #d2d2d7' : '',
}));

const onImageError = (): void => {
  error.value = `Warning: can not load image ${props.img}`;
  console.warn(error.value);
};
</script>
