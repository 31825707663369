import { CountryDto, RegionDto, UserRoleDto } from '@/castapi';
import { USER_GROUP_IDS } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getDictionaryApi, getErrorMessage, getOrganizationsApi } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

// Note: CountryDto was generated with field '_native' instead of 'native'
export interface CountryWithNativeDto extends CountryDto {
  native: string;
}

const logger = new AppLogger('dictionary state');

interface IDictionaryState {
  countries: CountryDto[];
  countriesLoading: boolean;
  countriesLoadError?: string;
  regions: Record<string, RegionDto>;
  regionsLoading: boolean;
  regionsLoadError?: string;
  userRoleTypes: UserRoleDto[];
  userRoleTypesLoadError?: string;
}

const initialState = (): IDictionaryState => ({
  countries: [],
  countriesLoading: false,
  countriesLoadError: undefined,
  regions: {},
  regionsLoading: false,
  regionsLoadError: undefined,
  userRoleTypes: [],
  userRoleTypesLoadError: undefined,
});

type Getters = {
  userRoleTypes: UserRoleDto[];
};

type ActionParams = IActionParams<IDictionaryState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    COUNTRIES_LOADING(state: IDictionaryState): void {
      state.countriesLoadError = undefined;
      state.countriesLoading = true;
    },
    COUNTRIES_LOADED(state: IDictionaryState, countries: CountryDto[]): void {
      state.countries = countries;
      state.countriesLoading = false;
    },
    COUNTRIES_LOAD_ERROR(state: IDictionaryState, error: Error): void {
      state.countriesLoadError = getErrorMessage(error);
      state.countriesLoading = false;
    },
    REGIONS_LOADING(state: IDictionaryState): void {
      state.regionsLoadError = undefined;
      state.regionsLoading = true;
    },
    REGIONS_LOADED(state: IDictionaryState, { regions, countryId }: { regions: RegionDto; countryId: string }): void {
      state.regions = { ...state.regions, [countryId]: regions };
      state.regionsLoading = false;
    },
    REGIONS_LOAD_ERROR(state: IDictionaryState, error: Error): void {
      state.regionsLoadError = getErrorMessage(error);
      state.regionsLoading = false;
    },
    ROLES_LOADED(state: IDictionaryState, roles: UserRoleDto[]): void {
      state.userRoleTypes = roles;
      state.userRoleTypesLoadError = undefined;
    },
    ROLES_LOAD_ERROR(state: IDictionaryState, error: Error): void {
      state.userRoleTypesLoadError = getErrorMessage(error);
    },
    RESET_STATE(state: IDictionaryState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    async getCountries({ state, commit }: ActionParams): Promise<void> {
      try {
        if (!state.countries.length) {
          commit('COUNTRIES_LOADING');
          const response = await getDictionaryApi().countryControllerFindAll();
          commit('COUNTRIES_LOADED', response.data);
        }
      } catch (error) {
        commit('COUNTRIES_LOAD_ERROR', error);
        logger.captureStoreError('getCountries', error);
      }
    },
    async getCountryRegions({ state, rootGetters, commit }: ActionParams, countryId: number): Promise<void> {
      try {
        if (!countryId || state.regions[countryId]) {
          return;
        }
        commit('REGIONS_LOADING');
        const response = await getDictionaryApi(rootGetters['login/accessToken']).regionControllerFindCountryRegions(
          countryId,
        );
        commit('REGIONS_LOADED', { countryId, regions: response.data });
      } catch (error) {
        commit('REGIONS_LOAD_ERROR', error);
        logger.captureStoreError('getCountryRegions', error, { countryId });
      }
    },
    async getUserRoles(
      { state, rootGetters, commit }: ActionParams,
      payload: { accessToken: string; force?: boolean },
    ): Promise<void> {
      try {
        if (payload.force || !state.userRoleTypes.length) {
          const response = await getOrganizationsApi(
            payload.accessToken || rootGetters['login/accessToken'],
          ).organizationsControllerGetUserRoleTypes();
          commit('ROLES_LOADED', response.data);
        }
      } catch (error) {
        commit('ROLES_LOAD_ERROR', error);
        logger.captureStoreError('getUserRoles', error);
      }
    },
  },
  getters: {
    countries: (state: IDictionaryState): CountryDto[] => state.countries,
    countriesLoading: (state: IDictionaryState): boolean => state.countriesLoading,
    countriesLoadError: (state: IDictionaryState): undefined | string => state.countriesLoadError,
    regions: (state: IDictionaryState): Record<string, RegionDto> => state.regions,
    regionsLoading: (state: IDictionaryState): boolean => state.regionsLoading,
    regionsLoadError: (state: IDictionaryState): undefined | string => state.regionsLoadError,
    userRoleTypes: (state: IDictionaryState): UserRoleDto[] => state.userRoleTypes,
    // userRoleTypesLoadError: (state:IDictionaryState) => state.userRoleTypesLoadError,
    roleTypeGetter:
      (_state: IDictionaryState, getters: Getters) =>
      (roleId: number): undefined | UserRoleDto =>
        getters.userRoleTypes.find(type => type.userRoleId === roleId),
    staffRoleTypes: (state: IDictionaryState, getters: Getters): UserRoleDto[] =>
      state &&
      getters.userRoleTypes.filter(
        (role: UserRoleDto) =>
          role.userGroupRef === USER_GROUP_IDS.ADMIN /* && role.userRoleId !== USER_ROLE_IDS.SUPER_MANAGER_ROLE_ID*/,
      ),
  },
};
