<template>
  <v-snackbar v-model="show" :color="color" :top="topAndCentered" :centered="topAndCentered" :timeout="timeout || 3000">
    <span v-html="message" />
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import store from '@/store';

const show = computed<boolean>({
  get() {
    return store.getters['snackbar/show'];
  },
  set(value: boolean) {
    if (!value) {
      store.commit('snackbar/CLOSE');
    }
  },
});
const message = computed<string>(() => store.getters['snackbar/message']);
const color = computed<string>(() => store.getters['snackbar/color']);
const topAndCentered = computed<string>(() => store.getters['snackbar/topAndCentered']);
const timeout = computed<number>(() => store.getters['snackbar/timeout'] * 1000);
</script>
